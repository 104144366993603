import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import componentFinder from '../util/componentFinder';
import options from '../../templates/richTextRenderers';


const useStyles = props =>
  makeStyles(theme => ({
    titleName:{
        fontSize: '1.1875rem',
      },
    titleLocation:{
      fontSize: '1.1875rem',
      fontWeight: 'bold',
    },
    text:{
      fontSize: '2.4883125rem',
    },
    highlightIcon:{
        position: 'relative',
        top: '-21%',
        width:'20%',
        [theme.breakpoints.down('sm')]: {
            width:'14%',
            top: '-21%',
            marginLeft: '30%',
          },
      },
      highlightImage:{
        width:'43%',
        [theme.breakpoints.down('sm')]: {
            width:'30%',
            marginLeft:'30%',
          },
      },
      imagesContainer:{
          flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
          paddingTop: '100px',
          flexDirection: 'row',
      },
    },
  }));

export default props => {
  const data = props.data[props.componentId];

  const classes = useStyles({ ...props, data })();

  const getDirection = orientation =>
  orientation === 'Image Left' ? 'row-reverse' : 'row';


  let bgcolor =
    data.backgroundColor === 'Red' ? 'primary.main' : '';

  const direction = getDirection(data.orientation);

  const hasImage = Boolean(data.image)
  const hasIcon = Boolean(data.icon)
  const hasName = Boolean(data.name)
  const hasLocation = Boolean(data.Location)
  const hasFont = Boolean(data.testimonialFontSize);


  return (
    <Box bgcolor={bgcolor} py={5} className={classes.wrapper}>
      <Container maxWidth="lg">
        <Grid container direction={direction} alignItems="center">
        <Grid item xs={12} sm={1} lg={1}></Grid>
            <Grid item xs={12} sm={7} lg={7}>
                {hasFont &&<Typography component='span' style={{fontSize:`${data.testimonialFontSize}rem`}}>
                    {documentToReactComponents(data.testimonialText.json, options())}
                </Typography>}
            </Grid>
            <Grid item xs={12} sm={1} lg={1}></Grid>
            <Grid item xs={12} sm={3} lg={3} className={classes.imagesContainer}>
                <Grid item container xs={12} sm={12}>
                    <Grid item xs={12} sm={12} >
                      <Box>
                        <Box>  
                          {hasName && 
                            <Typography className = {classes.titleName}>
                              {data.name}
                            </Typography>}               
                        </Box>
                        <Box>  
                            {hasLocation &&
                              <Typography className = {classes.titleLocation}>
                                {data.location}
                              </Typography>}                
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} >
                      {hasImage &&
                        <Box className={classes.highlightImage}>
                          <Img fluid={data.image.fluid}/>
                        </Box>}
                      {hasIcon &&
                        <Box className={classes.highlightIcon}>
                          <Img fluid={data.icon.fluid}/>
                        </Box>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
